<template>
    <div>
        <div v-if="hasPermission">
            <!-- Filters -->
            <artists-list-filters :role-filter.sync="roleFilter" :plan-filter.sync="planFilter" :status-filter.sync="statusFilter" :role-options="roleOptions" :plan-options="planOptions" :status-options="statusOptions" @filter="fetchArtists" />

            <!-- Table Container Card -->
            <b-card no-body class="mb-0">
                <div class="m-2">
                    <!-- Table Top -->
                    <b-row>
                        <!-- Per Page -->
                        <b-col cols="12" md="6" class="d-flex align-items-center justify-content-start mb-1 mb-md-0">
                            <label>Show</label>
                            <b-form-select v-model="perPage" :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'" :options="perPageOptions" :clearable="false" class="per-page-selector d-inline-block mx-50" @change="changePageArtists({ page: 1, itemsPerPage: $event })" />
                            <label>entries</label>
                        </b-col>
                    </b-row>
                </div>

                <b-table ref="refUserListTable" class="position-relative pb-3" :items="artists" responsive :fields="tableColumns" :per-page="perPage" primary-key="id" show-empty empty-text="No matching records found">
                    <!-- Column: User -->
                    <template #cell(user)="data">
                        <b-media class="align-items-center">
                            <template #aside>
                                <b-avatar size="32" :src="data.item.thumbnail" :text="avatarText(data.item.name)" :variant="`light-${resolveUserRoleVariant(data.item.role)}`" :to="{ name: 'apps-artists-view', params: { id: data.item.id } }" />
                            </template>
                            <b-link :to="{ name: 'apps-artists-view', params: { id: data.item.id } }" class="font-weight-bold d-block text-nowrap">
                                {{ data.item.name }}
                            </b-link>
                            <small class="text-muted">{{ data.item.cell_number }}</small>
                        </b-media>
                    </template>

                    <!-- Column: Role -->
                    <!--        <template #cell(role)="data">-->
                    <!--          <div class="text-nowrap">-->
                    <!--            <feather-icon-->
                    <!--              :icon="resolveUserRoleIcon(data.item.role)"-->
                    <!--              size="18"-->
                    <!--              class="mr-50"-->
                    <!--              :class="`text-${resolveUserRoleVariant(data.item.role)}`"-->
                    <!--            />-->
                    <!--            <span class="align-text-top text-capitalize">{{ data.item.role }}</span>-->
                    <!--          </div>-->
                    <!--        </template>-->
                    <!-- Column: Actions -->
                    <template #cell(actions)="data">
                        <b-dropdown variant="link" no-caret :right="$store.state.appConfig.isRTL">
                            <template #button-content>
                                <feather-icon icon="MoreVerticalIcon" size="16" class="align-middle text-body" />
                            </template>
                            <b-dropdown-item :to="{ name: 'apps-artists-view', params: { id: data.item.id } }">
                                <feather-icon icon="FileTextIcon" />
                                <span class="align-middle ml-50">Details</span>
                            </b-dropdown-item>

                            <b-dropdown-item :to="{ name: 'apps-artists-edit', params: { id: data.item.id } }">
                                <feather-icon icon="EditIcon" />
                                <span class="align-middle ml-50">Edit</span>
                            </b-dropdown-item>

                            <b-dropdown-item @click="deleteArtist(data.item.id)">
                                <feather-icon icon="TrashIcon" />
                                <span class="align-middle ml-50">Delete</span>
                            </b-dropdown-item>
                        </b-dropdown>
                    </template>
                </b-table>
                <div class="mx-2 mb-2">
                    <b-row>
                        <b-col cols="12" sm="6" class="d-flex align-items-center justify-content-center justify-content-sm-start">
                            <span class="text-muted">Showing {{ pagination.pageStart }} to {{ pagination.pageStop }} of {{ pagination.itemsLength }} entries</span>
                        </b-col>
                        <!-- Pagination -->
                        <b-col cols="12" sm="6" class="d-flex align-items-center justify-content-center justify-content-sm-end">
                            <b-pagination v-model="currentPage" :total-rows="pagination.itemsLength" :per-page="perPage" first-number last-number class="mb-0 mt-1 mt-sm-0" prev-class="prev-item" next-class="next-item" @change="changePageArtists({ page: $event, itemsPerPage: perPage })">
                                <template #prev-text>
                                    <feather-icon icon="ChevronLeftIcon" size="18" />
                                </template>
                                <template #next-text>
                                    <feather-icon icon="ChevronRightIcon" size="18" />
                                </template>
                            </b-pagination>
                        </b-col>
                    </b-row>
                </div>
            </b-card>
        </div>
        <div v-else class="text-center">
            <h1>⛔ You are not Allowed to be here ⛔</h1>
        </div>
    </div>
</template>

<script>
import { BCard, BRow, BCol, BFormInput, BFormSelect, BButton, BTable, BMedia, BAvatar, BLink, BBadge, BDropdown, BDropdownItem, BPagination } from "bootstrap-vue";
import vSelect from "vue-select";
import store from "@/store";
import { ref, onUnmounted, onMounted } from "@vue/composition-api";
import { avatarText } from "@core/utils/filter";
import ArtistRepository from "@/abstraction/repository/artistRepository";
import ArtistsListFilters from "./ArtistsListFilters";
import useArtistList from "./useArtistList";

const artistRepository = new ArtistRepository();

export default {
    components: {
        ArtistsListFilters,

        BCard,
        BRow,
        BCol,
        BFormInput,
        BFormSelect,
        BButton,
        BTable,
        BMedia,
        BAvatar,
        BLink,
        BBadge,
        BDropdown,
        BDropdownItem,
        BPagination,

        vSelect,
    },
    setup() {
        const USER_APP_STORE_MODULE_NAME = "app-user";
        // UnRegister on leave
        onUnmounted(() => {
            if (store.hasModule(USER_APP_STORE_MODULE_NAME)) store.unregisterModule(USER_APP_STORE_MODULE_NAME);
        });

        const roleOptions = [
            { label: "Admin", value: "admin" },
            { label: "Author", value: "author" },
            { label: "Editor", value: "editor" },
            { label: "Maintainer", value: "maintainer" },
            { label: "Subscriber", value: "subscriber" },
        ];

        const planOptions = [
            { label: "Basic", value: "basic" },
            { label: "Company", value: "company" },
            { label: "Enterprise", value: "enterprise" },
            { label: "Team", value: "team" },
        ];

        const statusOptions = [
            { label: "Pending", value: "pending" },
            { label: "Active", value: "active" },
            { label: "Inactive", value: "inactive" },
        ];

        const {
            fetchArtists,
            tableColumns,
            perPage,
            currentPage,
            perPageOptions,
            searchQuery,
            sortBy,
            isSortDirDesc,
            artists,
            pagination,

            // UI
            resolveUserRoleVariant,
            resolveUserRoleIcon,
            resolveUserStatusVariant,

            // Extra Filters
            roleFilter,
            planFilter,
            statusFilter,
        } = useArtistList();

        onMounted(async () => {
            await fetchArtists();
            // artists.value = artistList.data
            // pagination.value = artistList.pagination
        });
        const changePageArtists = async (paginate) => {
            await fetchArtists(paginate);
            await window.scrollTo(0, 0);
            // artists.value = artistList.data
            // pagination.value = artistList.pagination
        };
        const hasPermission = ref(false);
        const checkPermissions = () => {
            let logedInUserPermissions = JSON.parse(localStorage.getItem("userData")).permissions.map((item) => {
                return item.name;
            });
            console.log("SSS");
            if (logedInUserPermissions.includes("Artist.Singer.list")) {
                hasPermission.value = true;
            }
        };
        checkPermissions()
        return {
            hasPermission,
            changePageArtists,
            artists,
            pagination,
            fetchArtists,
            tableColumns,
            perPage,
            currentPage,
            perPageOptions,
            searchQuery,
            sortBy,
            isSortDirDesc,

            // Filter
            avatarText,

            // UI
            resolveUserRoleVariant,
            resolveUserRoleIcon,
            resolveUserStatusVariant,

            roleOptions,
            planOptions,
            statusOptions,

            // Extra Filters
            roleFilter,
            planFilter,
            statusFilter,
        };
    },
    methods: {
        async deleteArtist(artistId) {
            await artistRepository.delete(artistId);
            const index = this.artists.findIndex((x) => x.id === artistId);
            this.$delete(this.artists, index);
        },
    },
};
</script>

<style lang="scss" scoped>
.per-page-selector {
    width: 90px;
}
</style>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
</style>
